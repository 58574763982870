import { useGlobalStore } from "@/stores/global";

export const useRegionList = async () => {
  return useFetchSafely(() => `regions`);
};

export const useClinicSearchDetail = async () => {
  return useFetchSafely(() => `pages/clinics`);
};

export const usePopularCitiesList = async () => {
  return useFetchSafely(() => `popular-cities`);
};

export const useClinicListsByCity = async (mapData) => {
  return useFetchSafely(`clinics`, {
    params: mapData,
  });
};

export const useCityDetailPage = async (slug) => {
  return useFetchSafely(() => `city/${slug}`);
};

export const useFetchClinicDetailBySlug = async (slug) => {
  return useFetchSafely(() => `clinics/${slug}`);
};

export const useBlockReview = async (slug) => {
  return useFetchSafely(() => `customer-reviews/reviews?block=${slug}`);
};
